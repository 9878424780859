import {
  CancellationToken,
  editor,
  IPosition,
  languages,
  Position,
} from 'monaco-editor/esm/vs/editor/editor.api';
import ICodeEditor = editor.ICodeEditor;
import ITextModel = editor.ITextModel;
import CompletionContext = languages.CompletionContext;
import CompletionItem = languages.CompletionItem;
import CompletionItemProvider = languages.CompletionItemProvider;
import ProviderResult = languages.ProviderResult;
import CompletionList = languages.CompletionList;
import IWordAtPosition = editor.IWordAtPosition;
import CompletionItemKind = languages.CompletionItemKind;
import CompletionItemInsertTextRule = languages.CompletionItemInsertTextRule;
import {RulesetTargetKind} from '../../../../../api-client/rulesets/types';

export type Submodule = string | Module | [Module] | [];
interface Module extends Record<string, Submodule> {}
type ModuleSchema = Record<string, Module>;

function getImportedModules(doc: ITextModel, schema?: ModuleSchema): string[] {
  // should match every line starting with 'import "<module>"'
  const importRegexp = RegExp(
    `import "(${schema ? Object.keys(schema).join('|') : '.+'})"`
  );
  return doc
    .getLinesContent()
    .filter((line: string) => {
      return importRegexp.test(line);
    })
    .map<string>((line: string) => {
      return line.split('"')[1];
    });
}

function getNextImportLine(doc: ITextModel) {
  const lastLine = doc.getLineCount();
  const lastImportLine =
    doc.findPreviousMatch(
      '^import "(.+)"',
      {lineNumber: lastLine, column: doc.getLineMaxColumn(lastLine)},
      true,
      false,
      null,
      false
    )?.range.endLineNumber || 0;
  return lastImportLine + 1;
}

export function manuallyInsertSnippet(
  monacoEditor: ICodeEditor,
  snippet: string,
  dependencies: string[]
) {
  const breaklinePrefix = '\n\n';
  const snippetController = monacoEditor.getContribution(
    'snippetController2'
  ) as unknown as {
    insert(template: string): void;
  };
  if (dependencies.length) {
    const importedModules = new Set(
      getImportedModules(monacoEditor.getModel()!)
    );
    const notImportedDependencies = dependencies.filter(
      (dep) => !importedModules.has(dep)
    );
    if (notImportedDependencies.length) {
      const nextImportLine = getNextImportLine(monacoEditor.getModel()!);
      monacoEditor.setPosition({
        column: 1,
        lineNumber: nextImportLine,
      });
      snippetController.insert(
        notImportedDependencies.map((dep) => `import "${dep}"`).join('\n') +
          '\n'
      );
    }
  }

  const lineOfInsertion = monacoEditor.getModel()!.getLineCount();

  monacoEditor.setPosition({
    column: 2,
    lineNumber: lineOfInsertion,
  });

  snippetController.insert(breaklinePrefix + snippet);

  monacoEditor.revealLineInCenter(lineOfInsertion);
  monacoEditor.focus();
}

/*
      Convert a directory of module schemas to a map of Modules to flattened lists of CompletionItems
  */
async function parseSchema(kind: RulesetTargetKind): Promise<ModuleSchema> {
  const schema: ModuleSchema = {};
  const modules = import.meta.glob('../modules/*.ts');
  await Promise.all(
    Object.keys(modules)
      .map((path) =>
        (modules[path]() as Promise<{default: Module}>).then((contents) => {
          const moduleName = path.match(/..\/modules\/(.+).ts/)![1];
          schema[moduleName] = contents.default as Module;
          return;
        })
      )
      .concat([
        import(`../modules/vt/${kind}.json`).then((contents) => {
          schema.vt = contents.default as Module;
          return;
        }),
      ])
  );
  return schema;
}

export function makeCompletionItem(
  label: string,
  path: string,
  schemaType: 'enum' | 'method' | 'property' | 'array' | 'dictionary'
): Omit<CompletionItem, 'range'> {
  switch (schemaType) {
    case 'enum':
      return {
        kind: CompletionItemKind.Enum,
        label,
        filterText: `${path}.${label}`,
        insertText: label,
        insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
      };
    case 'method':
      return {
        kind: CompletionItemKind.Method,
        label,
        filterText: `${path}.${label}`,
        detail: `${label}()`,
        insertText: `${label}($1)`,
        insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
      };
    case 'property':
      return {
        kind: CompletionItemKind.Property,
        label,
        filterText: `${path}.${label}`,
        insertText: label,
        insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
      };
    case 'array':
      return {
        kind: CompletionItemKind.Unit,
        label,
        filterText: `${path}.${label}`,
        detail: `${label}[index]`,
        insertText: `${label}[$\{1:index}]`,
        insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
      };
    case 'dictionary':
      return {
        kind: CompletionItemKind.Struct,
        label,
        filterText: `${path}.${label}`,
        detail: `${label}["key"]`,
        insertText: `${label}["$\{1:key}"]`,
        insertTextRules: CompletionItemInsertTextRule.InsertAsSnippet,
      };
  }
}

export class YaraCompletionItemProvider implements CompletionItemProvider {
  public wordDefinition = /[a-zA-Z0-9\._]+/;
  public triggerCharacters = ['.'];

  private constructor(
    public schema: ModuleSchema,
    private userHasPrivilege: (privilege: string) => boolean
  ) {}

  public static async new(
    kind: RulesetTargetKind,
    userHasPrivilege: (privilege: string) => boolean
  ) {
    return new YaraCompletionItemProvider(
      await parseSchema(kind),
      userHasPrivilege
    );
  }

  private getModuleMember(
    doc: ITextModel,
    position: IPosition
  ): IWordAtPosition {
    const firstPreviousWhitespace = doc.findPreviousMatch(
      '\\s',
      position,
      true,
      false,
      null,
      false
    );
    const startColumn = firstPreviousWhitespace?.range.endColumn || 0;
    const endColumn = position.column;
    return {
      word: doc.getValueInRange({
        startColumn,
        endColumn,
        startLineNumber: position.lineNumber,
        endLineNumber: position.lineNumber,
      }),
      endColumn,
      startColumn,
    };
  }

  private access(member: string, module: Submodule) {
    if (
      module instanceof Array &&
      module.length === 1 &&
      !!member.match(/^\[(.+)\]$/)
    ) {
      return module[0];
    } else if (!(module instanceof Array) && 'string' !== typeof module) {
      return module[member] || null;
    }
    return null;
  }

  private getFromPath(path: string[], module: Submodule) {
    let currentSubmodule = module;
    for (const member of path) {
      const submodule = this.access(member, currentSubmodule);
      if (!submodule) return null;
      currentSubmodule = submodule;
    }
    return currentSubmodule;
  }

  public provideCompletionItems(
    doc: ITextModel,
    pos: Position,
    _: CompletionContext,
    token: CancellationToken
  ): ProviderResult<CompletionList> {
    return new Promise((resolve, reject) => {
      token.onCancellationRequested(() => {
        resolve(undefined);
      });
      try {
        const items: CompletionList = {suggestions: []};
        const fullTerm = this.getModuleMember(doc, pos);
        if (!fullTerm) return resolve(undefined);
        const [_, ...terms]: string[] = fullTerm.word
          .split('.')
          .flatMap((member) =>
            member.split('[').map((m, i) => (i === 0 ? m : `[${m}`))
          )
          .reverse();
        const path = terms.reverse();
        const importedModules = getImportedModules(doc, this.schema);
        const nextImportLine = getNextImportLine(doc);
        const parentModule = this.getFromPath(path, this.schema);
        // Check if the first term is a valid module
        if (
          parentModule &&
          'string' !== typeof parentModule &&
          !(parentModule instanceof Array)
        ) {
          items.suggestions = Object.keys(parentModule)
            .map((label) => {
              const entry = makeCompletionItem(
                label,
                path.join('.'),
                'string' === typeof parentModule[label]
                  ? (parentModule[label] as 'dictionary' | 'enum' | 'method')
                  : parentModule[label] instanceof Array
                  ? 'array'
                  : 'property'
              );
              return {
                ...entry,
                additionalTextEdits: importedModules.includes(
                  terms[0] || (entry.label as string)
                )
                  ? []
                  : [
                      {
                        range: {
                          startColumn: 0,
                          startLineNumber: nextImportLine,
                          endColumn: 0,
                          endLineNumber: nextImportLine,
                        },
                        text: `import "${terms[0] || entry.label}"\n`,
                      },
                    ],
              };
            })
            .filter(
              (entry) =>
                entry.label !== 'gti_assessment' ||
                ['google-threat-intel', 'staff'].some((privilege) =>
                  this.userHasPrivilege(privilege)
                )
            ) as CompletionItem[];
          return resolve(items);
        }
        resolve(undefined);
      } catch (error) {
        reject(error);
      }
    });
  }

  public resolveCompletionItem(
    item: CompletionItem,
    token: CancellationToken
  ): ProviderResult<CompletionItem> {
    return new Promise((resolve) => {
      token.onCancellationRequested(() => {
        resolve(undefined);
      });
      // TODO: Add documentation - VT Filetypes would be a great place to start
      resolve(item);
    });
  }
}
