import {ContributorEntityTypes} from '../rulesets';
import {ApiService, ObjectDescriptor} from '../service';

export class ApiShareableEntities {
  constructor(private service: ApiService) {}
  public get(
    filter: string,
    type: ContributorEntityTypes
  ): Promise<{
    data: ObjectDescriptor<ContributorEntityTypes>[];
  }> {
    return this.service.get({
      endPoint: `get_shareable_entities/${filter}`,
      params: {
        object_type: type,
      },
    });
  }
}
